import React, { FC } from "react";

import { ICheckmarkField } from "common/types";

import { P } from "@global/Texts";

const CheckmarkField: FC<ICheckmarkField> = ({ label, value, onClick, description }) => {
	return (
		<div className='flex flex-col space-y-2 lg:flex-row lg:space-y-0 lg:space-x-4'>
			<P className='text-grey'>
				{label}
			</P>
			<div className="flex space-x-2 items-center">
				<input
					type="checkbox"
					className="
					w-6
					h-6
					rounded
					bg-gray-midlight
					accent-yellow
					text-yellow
					cursor-pointer
					border-0
					border-white
					hover:border-2
					checked:bg-blue-dark
					checked:hover:bg-blue-dark
					checked:active:bg-blue-dark
					checked:focus:bg-blue-dark
					focus:bg-blue-dark
					focus:outline-none
					focus:ring-yellow
					focus:active:ring-yellow
				"
					id="checkbox"
					readOnly
					checked={value}
					onClick={onClick}
				/>
				<label className='select-none text-left text-white'>
					{description}
				</label>
			</div>
		</div>
	)
}

export default CheckmarkField;